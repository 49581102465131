:root{
  --scale: 0.05vw;
  --main: 48vw;
}

.theme-load .theme-light, .theme-load .theme-dark{
  transition: 0.25s ease-in-out;
}
.theme-light{
  --neutral-1: #fff;
  --neutral-1-30: rgba(255, 255, 255, 30%);
  --neutral-1-60: rgba(255, 255, 255, 60%);
  
  --neutral-2: #000;
  --neutral-2-30: rgba(0, 0, 0, 30%);
  --neutral-2-40: rgba(0, 0, 0, 40%);
  --accent-1: #0141FF;
  --accent-1-20: rgba(1, 65, 255, 40%);
  --accent-1-0: rgba(1, 65, 255, 0%);
  --accent-2: #FF3D00;
  --accent-2-20: rgba(255, 60, 0, 20%);
  --alpha: rgba(0, 0, 0, 0);
}
.theme-dark{
  --neutral-1: rgb(9, 19, 49);
  --neutral-1-30: rgba(9, 19, 49, 30%);
  --neutral-1-60: rgba(9, 19, 49, 60%);
  
  --neutral-2: rgb(194, 206, 241);
  --neutral-2-30: rgba(194, 206, 241, 30%);
  --neutral-2-40: rgba(194, 206, 241, 40%);
  --accent-1: #0141FF;
  --accent-1-20: rgba(1, 65, 255, 40%);
  --accent-1-0: rgba(1, 65, 255, 0%);
  --accent-2: #FF3D00;
  --accent-2-20: rgba(255, 60, 0, 20%);
  --alpha: rgba(0, 0, 0, 0);
}


html{
  font-size: var(--scale);
}

div:empty{
  display: block;
}

html, body, #root{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
body{
  font-size: 18rem;
  color: var(--neutral-2);
}
main{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  position: relative;
  color: var(--neutral-2);
  background-color: var(--neutral-1);
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif;
}
*::selection{
  color: var(--neutral-1);
  background-color: var(--neutral-2);
  border-radius: 20rem;
}
p,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4
text,
a{
  color: var(--neutral-2);
}
p{
  padding: 0;
  margin: 0;
  display: inline;
}

a{
  cursor: pointer;
  text-decoration: unset;
}

h1,
.h1{
  font-size: 120rem;
  font-weight: 800;
  line-height: 84%;
}
h2,
.h2{
  font-size: 50rem;
  font-weight: 800;
  line-height: 84%;
}
h3,
.h3{
  font-size: 32rem;
  font-weight: 700;
  line-height: 96%;
}
h4,
.h4{
  font-size: 24rem;
  font-weight: 500;
  line-height: 110%;
}
button,
.button,
text,
.text,
p,
.p{
  font-size: 18rem;
  font-weight: 500;
  line-height: 170%;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
}

.cat-animation{
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.cat-animation > div{
  width: 150% !important;
}

/* background */
.dynamic-background{
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.bgg{
  width: 50vw;
  height: 50vw;
  background: linear-gradient(180deg, #0141FF 0%, rgb(255 0 0 / 35%) 100%);
  border-radius: 9999rem;
  filter: blur(222.5rem);
  position: absolute;
  top: 423rem;
  right: -600rem;
}

/* content */

.nav{
  padding: 24rem 30rem;
  width: calc(var(--main) + 60rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 50rem;
}
header{
  z-index: 1000;
  position: fixed;
  top: 70rem;
  width: 100%;
  height: auto;
  padding: 0rem 40rem;
}
.header-link{
  display: flex;
}
.iconheader{
  height: 30rem;
  width: auto;
  display: flex;
}
.block-content-main{
  min-height: 100vh;
  max-height: max-content;
}
.block-content{
  width: 100%;
  max-height: max-content;
  padding: 70rem;
  display: flex;
  flex-direction: column;
  padding: calc(100vh - 570rem) 70rem 70rem 70rem;
  justify-content: flex-end;
  z-index: 4;
  gap: 60rem;
}


.box-content{
  display: flex;
  gap: 80rem;
  width: var(--main);
}

button, .button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  border-radius: 60rem;
  cursor: pointer;
  padding: 24rem 50rem 24rem 50rem;
}

.btn-white{
  background-color: var(--neutral-1);
  color: var(--neutral-2);
  fill: var(--neutral-2);
  stroke: var(--neutral-2);
}
.btn-black{
  background-color: var(--neutral-2);
  color: var(--neutral-1);
  fill: var(--neutral-1);
  stroke: var(--neutral-1);
}
.btn-sec{
  color: var(--neutral-2  );
  fill: var(--neutral-2);
  background-color: var(--alpha);
  border: solid 1rem var(--neutral-2-30);
}
.btn-icon{
  padding: 24rem 50rem 24rem 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  border-radius: 20rem;
}  
.btn-icon svg, .btn-icon img{
  width: 48rem;
  height: 48rem;

  fill: inherit;
}




.box-contry{
  overflow: hidden;
  width: 25%;
  height: auto;
  border-radius: 20rem;
  border: solid 1rem var(--neutral-2-30);
}
.contry-indicator{
  width: 26rem;
  height: 14rem;
  background-color: #83FF88;
  border-radius: 12rem;
  box-shadow: 0 0 0 2rem black;
}
.box-contry img{
  aspect-ratio: 1 / 1;
  width: 100%;
  height: auto;
}
.box-contry .title{
  background: var(--neutral-2);
  padding: 18px 24px;
  align-items: center;
}
.box-contry p{
  color: var(--neutral-1);
}


.focus{
  transition: 0.1s cubic-bezier(1, -0.27, 0, 1.58);
  box-shadow: var(--accent-1) 0 0 0 0rem;
}
.focus:hover{
  box-shadow: var(--accent-1-20) 0 0 0 8rem;
}

.focus-bg{
  border-radius: 20rem;
  transition: 0.1s cubic-bezier(1, -0.27, 0, 1.58);
  background-color: var(--accent-1-0);
}

.focus-bg:hover{
  background-color: var(--accent-1-20);
}

.group-text{
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.main-block{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.align-c{
  align-items: center;
}
.align-t{
  align-items: flex-start;
}
.align-b{
  align-items: flex-end;
}

.gap10{
  gap: 10rem;
}
.gap20{
  gap: 20rem;
}
.gap30{
  gap: 30rem;
}
.gap40{
  gap: 40rem;
}
.gap50{
  gap: 50rem;
}
.gap60{
  gap: 60rem;
}
.space-between{
  justify-content: space-between;
}

.w-100{
  width: 100%;
}
.w-80{
  width: 80%;
}
.w-60{
  width: 60%;
}
.w-55{
  width: 55%;
}
.w-50{
  width: 50%;
}
.w-45{
  width: 45%;
}
.w-40{
  width: 40%;
}
.w-20{
  width: 20%;
}

.h-100{
  height: 100%;
}
.h-80{
  height: 80%;
}
.h-60{
  height: 60%;
}
.h-40{
  height: 40%;
}
.h-20{
  height: 20%;
}

.glass{
  background-color: var(--neutral-1-60);
  border: solid 1rem var(--neutral-1-60);
  backdrop-filter: blur(50rem) saturate(2) contrast(1.04);
}

.top-line{
  border-top: 1rem solid var(--neutral-2-30);
  padding-top: 20rem;
}

.leading-trim-h1{
  position: relative;
  top: -9rem;
  bottom: -9rem;
  left: -8rem;
}

.theme-checkbox{
  position: relative;
  width: 78rem;
  height: 38rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1rem var(--neutral-2-30);
  border-radius: 32rem;
  fill: var(--neutral-2);
}
.theme-checkbox .sun, .theme-checkbox  .moon{
  width: 22rem;
  height: 22rem;
  transition: 0.8s cubic-bezier(0.67, -0.46, 0.08, 1.41);
  position: absolute;
  fill: var(--neutral-2);
  animation-delay: 0.7s;
}
.theme-light .theme-checkbox .sun, .theme-light .theme-checkbox .moon{
  transform: translate(19rem,0rem);
}
.theme-light .theme-checkbox .moon{
  transition: 1s cubic-bezier(0.67, -0.46, 0.08, 1.41);
  opacity: 0.3;
  transform: translate(-19rem,0rem) scale(0.6);
}

.theme-dark .theme-checkbox .sun, .theme-dark .theme-checkbox .moon{
  transform: translate(-19rem,0rem);
}
.theme-dark .theme-checkbox .sun{
  transition: 1s cubic-bezier(0.67, -0.46, 0.08, 1.41);
  opacity: 0.3;
  transform: translate(19rem,0rem) scale(0.6);
}

.card-plan{
  width: 100%;
  display: flex;
  gap: 30rem;
  flex-direction: column;
  border-radius: 20rem;
  border: solid 1rem var(--neutral-2-30);
  padding: 24rem;
}
.plan-line{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20rem 0rem;
  border-bottom: solid 1rem var(--neutral-2-30);
}
.plan-line:last-child{
  border-bottom: solid 0rem var(--neutral-2-30);
}
.plan-line p:last-child{
  text-align: right;
}


.text-aсcent-1{
  color: var(--accent-1);
}
.text-aсcent-2{
  color: var(--accent-2);
}
.old-prise{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-2-40);
}
.old-prise::after{
  content: "";
  position: absolute;
  background-color: var(--neutral-2);
  width: 100%;
  height: 2rem;
}